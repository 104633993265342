/**
 * Copyright ©2024 Drivepoint
 */

import React, {ReactNode, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {EventBus} from "@bainbridge-growth/node-frontend";
import DPPageStatus, {DPPageStatusInterface} from "../DPPageStatus/DPPageStatus.tsx";
import "./Page.css";

export type PageProps = {
  children: ReactNode;
};

export default function Page(props: PageProps): any {

  const status = useRef<DPPageStatusInterface>();

  const navigate = useNavigate();

  useEffect(() => {
    const registrations = EventBus.registerMany("system:error", (event: any) => {
      if (event.type === "system:error") {
        handleErrorStatus(event.message, event.refresh);
      }
    });
    return () => {
      EventBus.unregister(...registrations);
    };
  }, []);

  useEffect(() => {
    logger.debug(location.hash);
    if (status) {status.current?.hide();}
  }, [location.hash]);

  function handleErrorStatus(message: string, refreshAction: () => void = () => {navigate("/");}) {
    status.current?.show({
      variant: "error",
      title: "Something went wrong!",
      subtitle: `${message}\nPlease try again. If the issue persists please contact support.`,
      actions: [{
        icon: "refresh",
        title: "Refresh Add-in",
        click: refreshAction
      },
      {
        icon: "support_agent",
        title: "Contact Support",
        click: () => {}
      }]
    });
  }

  return <div className="page">
    <DPPageStatus ref={status} />
    {props.children}
  </div>;

}
