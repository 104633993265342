/**
 * Copyright ©2024 Drivepoint
 */

import {initializeApp} from "firebase/app";
import {getStorage, FirebaseStorage} from "firebase/storage";
import {FirebaseApp} from "@firebase/app";
import {getAuth, Auth, User} from "firebase/auth";
import {Config, EventBus, State} from "@bainbridge-growth/node-frontend";
import DrivepointUser from "./DrivepointUser.ts";
import ServerSentEventService from "./ServerSentEventService.ts";
import ModelSettings from "./microsoft/ModelSettings.ts";

export default class Firebase {

  private static _app: FirebaseApp;
  private static _auth: Auth;
  private static _storage: FirebaseStorage;
  private static _user: User | null;
  private static _token: string | undefined;

  static async start(): Promise<void> {
    Firebase._app = initializeApp(Config.get("firebase"));
    Firebase._auth = getAuth(Firebase.app);
    Firebase._storage = getStorage();
    Firebase.auth.onIdTokenChanged(Firebase.onIdTokenChanged);
    setInterval(() => { Firebase.user?.getIdToken(); }, 60000);
  }

  static async onIdTokenChanged(user: User | null): Promise<void> {
    Firebase._user = user;
    Firebase._token = await user?.getIdToken();
    EventBus.dispatch({type: "user:loading"} as any);
    if (user) {
      try {
        const drivepointUser = new DrivepointUser(user);
        await drivepointUser.load();
        State.set("user", drivepointUser);
        const settings = await ModelSettings.get();
        State.set("company", drivepointUser.companies.find(it => it.id === settings?.companyId));
        State.set("token", Firebase._token);
        ServerSentEventService.connect();
      } catch (error: any) {
        logger.error(error.message);
        EventBus.dispatch({type: "system:error", message: "An unexpected error occurred during authentication."} as any);
        Firebase.auth.signOut();
      }
    } else {
      ServerSentEventService.disconnect();
      State.set("user", null);
      State.set("company", null);
      State.set("token", null);
    }
    EventBus.dispatch({type: "user:loaded"} as any);
  }

  static get app(): FirebaseApp {
    return Firebase._app;
  }

  static get auth(): Auth {
    return Firebase._auth;
  }

  static get storage(): FirebaseStorage {
    return Firebase._storage;
  }

  static get user(): User | null {
    return Firebase._user;
  }

  static get token(): string | undefined {
    return Firebase._token;
  }

  static get bearer(): string {
    const token = Firebase.token;
    return token ? `Bearer ${token}` : "";
  }

}
