/**
 * Copyright ©2024 Drivepoint
 */

import React, {forwardRef, JSX, useEffect, useMemo, useRef} from "react";
import {Config, useStateChange, useStore} from "@bainbridge-growth/node-frontend";
import dayjs from "dayjs";
import {useModal} from "mui-modal-provider";
import {
  Avatar, Box, CircularProgress, DialogProps, Divider, Grid, Icon, ListItemIcon,
  ListItemText, MenuItem, MenuList, Typography
} from "@mui/material";

import {useNavigate} from "react-router-dom";
import DPPrimaryLogo from "../../../assets/drivepoint-logo-primary.png";
import DrivepointUser from "../../services/DrivepointUser.ts";
import Firebase from "../../services/Firebase.ts";
import Telemetry from "../../services/telemetry/Telemetry.ts";
import FilesStore from "../../stores/FilesStore.ts";
import PlanStore from "../../stores/PlanStore.ts";
import {PlanProps} from "../../types/ExcelModel.ts";
import ExcelUtilities from "../../utilities/ExcelUtilities.ts";
import DPDataFreshness from "../DPInfoComponents/DPDataFreshness/DPDataFreshness.tsx";
import DPPageLoadingVeil, {DPPageLoadingVeilInterface} from "../DPPageLoadingVeil/DPPageLoadingVeil.tsx";
import WebAppServerClient from "../../services/WebAppServerClient.ts";
import DPButton from "../DPButton/DPButton.tsx";
import DPDialog from "../DPDialog/DPDialog.tsx";
import DPDrawer, {DPDrawerInterface} from "../DPDrawer/DPDrawer.tsx";

import "./DPNavigationMenu.css";

export type NavigationMenuProps = {
};

function  NavigationMenu(props: NavigationMenuProps, ref: any): JSX.Element {

  const loader = useRef<DPPageLoadingVeilInterface>();
  const [plan] = useStore<PlanProps, PlanStore>(PlanStore);
  const [files] = useStore<Record<string, any>[], FilesStore>(FilesStore);

  const navigate = useNavigate();
  const {showModal} = useModal();

  const dpUser = useStateChange<DrivepointUser>("user");
  const company =  useStateChange<any>("company");
  const data_import_info = useStateChange<any[]>("data_imports_info");

  const planStatus = useMemo(() => {
    if (!plan) {return;}
    const currentPlan = files.find(el => el.id === plan.id) ?? {};

    if (!currentPlan) {return;}

    const isDirty = dayjs(currentPlan.lastModelUpdateTimestamp).isAfter(dayjs(currentPlan.last_dwh_sync_timesteamp));
    if (["ready", "dwh_saving_failed"].includes(currentPlan?.state) && isDirty) {
      return "unsync";
    }

    if (currentPlan?.state === "dwh_saving") {
      return "syncing";
    }

    return "sync";
  }, [files, plan]);

  const isImportDataInProgress = useMemo(() => data_import_info?.some(el => el.import_status === "progress") ?? false, [data_import_info]);

  const menuItems = useMemo(() => [
    {name: "Home", Icon: "home", click: () => {navigate("/HomePage");}},
    {name: "Sync to Drivepoint", Icon: "refresh", click: handleSync},
    {name: "Import Data", Icon: "downloading", click: () => {navigate("/ImportDataPage");}},
    (dpUser?.excelUser && dpUser?.excelUser?.role === "superAdmin") ?
      {name: "Update Actuals", Icon: "attach_money", click: handleUpdateActuals}
      : null,
    {name: "Roll Forward", Icon: "drive_file_move", click: () => {navigate("/RollForwardPage");}},
    {name: "Save as New Plan", Icon: "file_copy", click: () => {navigate("/NewPlanPage");}},
    {name: "Variance Review", Icon: "multiline_chart", click: () => {navigate("/VarianceReviewPage");}},
    {name: "Browse other Plans", Icon: "open_in_new", click: () => {window.open(`${Config.get("webapp.url")}/${company?.id}/plans`, "_blank");}},
    {name: "Help Center", Icon: "help", click: () => {window.open("https://docs.drivepoint.io/help", "_blank");}}
  ].filter(it => it), [company?.id, plan?.id]);

  useEffect(() => {
    (planStatus === "syncing")
      ? ExcelUtilities.toggleButtonState("Sync", false)
      : ExcelUtilities.toggleButtonState("Sync");
  }, [planStatus]);

  function handleSpinner(itemName: string) {

    let showSpinner = false;

    if (planStatus === "syncing" && itemName.toLowerCase() === "sync to drivepoint") {showSpinner = true;}
    if (itemName.toLowerCase() === "import data" && isImportDataInProgress) {showSpinner = true;}

    return showSpinner ? <CircularProgress size={20} /> : <></>;
  }

  function handleDisableMenuItem(itemName: string) {
    return planStatus === "syncing" && itemName.toLowerCase() === "sync to drivepoint";

  }

  async function handleSync() {
    await WebAppServerClient.request(`/api/company/${company.id}/plan/${plan.id}/sync`, "PATCH");
  }

  function handleUpdateActuals() {
    showModal(DPDialog, {
      children: renderUpdateActualsDialogBody(),
      title: "Update Actuals",
      hideHeaderDivider: true,
      actions: (props) => renderUpdateActualsDialogActions(props)
    });
  }

  function renderMenu() {
    return <MenuList>
      {menuItems.map((item, index) =>
        <Box key={item.name}>
          <MenuItem disabled={handleDisableMenuItem(item.name)} onClick={() => {
            Telemetry.track("click_drawer_menu", {menu_name: item.name});
            ref.current.toggle();
            item.click();
          }}>
            <ListItemIcon><Icon>{item.Icon}</Icon></ListItemIcon>
            <ListItemText>{item.name}</ListItemText>
            <Typography variant="body2">{handleSpinner(item.name)}</Typography>
          </MenuItem>
          {index !== (menuItems.length - 1) && <Divider/>}
        </Box>)}
    </MenuList>;
  }

  function renderFooterMenu() {
    return <MenuList>
      <MenuItem onClick={async () => {
        ref.current.toggle();
        Firebase.auth.signOut();
        await Telemetry.track("sign_out");
      }}>
        <ListItemIcon><Icon>logout</Icon></ListItemIcon>
        <ListItemText>Sign Out</ListItemText>
      </MenuItem>
    </MenuList>;
  }

  function renderTopText() {
    if (!dpUser?.excelUser || !company) {return <></>;}
    return <>
      <Typography variant="h6">Hello {dpUser.excelUser.firstName} {dpUser.excelUser.lastName}!</Typography>
      <Typography variant="body2">{company?.name}</Typography>
    </>;
  }

  function renderAvatar() {
    if (!dpUser?.excelUser || !company) {return <></>;}
    return <Avatar src={dpUser?.user?.photoURL}>
      {dpUser?.excelUser?.firstName[0]}{dpUser?.excelUser?.lastName[0]}
    </Avatar>;
  }

  function renderUpdateActualsDialogBody() {
    return <Grid container>
      <Grid item xs={12} mb={2}>
        <Typography variant="body2">
          This action will copy actuals from the <b>R-GL schedule</b> in this current model and send it to the Drivepoint Data Warehouse.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" style={{color: "var(--GREY-400)"}}>
          We typically use the actuals from the Live Model,
          but you can override it by using the actuals in this model by clicking 'Proceed'.
        </Typography>
      </Grid>
    </Grid>;
  }

  function renderUpdateActualsDialogActions(props: Pick<DialogProps, "onClose">) {
    return <Grid container justifyContent="flex-end">
      <Grid item>
        <DPButton label="Cancel" variant="text" onClick={() => {
          props.onClose({},"backdropClick");
        }} />
      </Grid>
      <Grid item>
        <DPButton label="Proceed" variant="contained" onClick={async () => {
          props.onClose({},"backdropClick");
          try {
            loader.current.show("Updating Actuals...");
            // TODO: THIS IS DUPLICATE CODE, CLEAN IT UP! both invokers for ImportFinancials should do the same thing.. and RollForward and UpdateFinancials should do it all BUT not replaceGeneralLedger()
            // TODO: Dana ADD a marker for the backend to set the async job know when the sharepoint sheet actually gets updated
            await Excel.run(async (context: Excel.RequestContext) => {
              context.workbook.save(Excel.SaveBehavior.save);
              const initialCalcMode = await ExcelUtilities.getCalculationMode(context);
              if (initialCalcMode == Excel.CalculationMode.manual){
                await ExcelUtilities.setCalculationMode(Excel.CalculationMode.automatic, context);
              }
              context.workbook.application.calculate('Full');
              const m_monthly_worksheet = context.workbook.worksheets.getItem("M - Monthly");
              await m_monthly_worksheet.calculate(true);
              await context.sync();
              if (initialCalcMode == Excel.CalculationMode.manual){
                await ExcelUtilities.setCalculationMode(Excel.CalculationMode.manual, context);
              }
            });
            await WebAppServerClient.request(`/api/company/${company.id}/plan/${plan.id}/saveFinancials`, "PATCH", {updateActuals: true});
            loader.current.hide();
          }
          catch (err: any) {
            loader.current.hide();
            logger.error(err.message);
          }
        }} />
      </Grid>
    </Grid>;
  }

  return <>
    <DPPageLoadingVeil ref={loader} />
    <DPDrawer
      PaperProps={{style: {maxWidth: "332px", width: "80%"}}}
      ref={ref}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12} p={2}><img alt="logo" src={DPPrimaryLogo} height="35px" /></Grid>
          <Grid item xs={12} p={2}>{renderAvatar()}</Grid>
          <Grid item xs={12} pl={2}>{renderTopText()}</Grid>
          <Grid item xs={12} pl={2} pt={2} pr={1}><DPDataFreshness /></Grid>
          <Grid item xs={12} p={2}>{renderMenu()}</Grid>
        </Grid>
        <Grid item xs={12} display="flex" alignItems="flex-end" p={2}>
          <Grid container>
            <Grid item xs={12}>
              {renderFooterMenu()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DPDrawer>
  </>;

}

const DPNavigationMenu = forwardRef<DPDrawerInterface, NavigationMenuProps>(NavigationMenu);

export default DPNavigationMenu;
