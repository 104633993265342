/**
 * Copyright ©2024 Drivepoint
 */

import React, {ReactNode, useRef, forwardRef, useImperativeHandle, useState} from "react";
import {useLocation} from "react-router-dom";
import {AppBar, Container, IconButton, Toolbar} from "@mui/material";
import {HelpOutlineRounded, Menu} from "@mui/icons-material";
import Pages from "../../services/Pages";
import BackgroundOperationsIndicator from "../BackgroundOperationsIndicator/BackgroundOperationsIndicator";
import DPNavigationMenu from "../DPNavigationMenu/DPNavigationMenu.tsx";

import type {DPDrawerInterface} from "../DPDrawer/DPDrawer";

import "./Header.css";

export type HeaderInterface = {
  updateTitle: (title: string) => void;
  updateAction: (element: ReactNode) => void
};

type HeaderProps = {};

function HeaderComponent(props, ref): any {

  const location = useLocation();

  const drawer = useRef<DPDrawerInterface>();

  const [title, setTitle] = useState<string | null>(null);
  const [actionElement, setActionElement] = useState<ReactNode | null>(null);

  useImperativeHandle(ref, (): HeaderInterface => ({
    updateTitle: (title: string) => {setTitle(title);},
    updateAction: (element: ReactNode) => {setActionElement(element);}
  }));

  function onPagesButtonClick(): void {
    drawer.current.toggle();
  }

  function onInfoClick(): void {
    window.open("https://docs.drivepoint.io/help", "_blank");
  }

  function getCurrentPageWithDefault(): any {
    if (location.pathname === "/") { return Pages.default; }
    return Pages.getPageByPath(location.pathname);
  }

  function renderQuestionButton(): any {
    return <IconButton className="header-toolbar-info-button" onClick={onInfoClick}>
      <HelpOutlineRounded />
    </IconButton>;
  }

  return <div className="header">
    <AppBar className="header-app-bar" position="static">
      <Container className="header-container" maxWidth="xl">
        <Toolbar className="header-toolbar" disableGutters>
          {actionElement ?? <IconButton className="header-toolbar-pages-button" onClick={onPagesButtonClick}>
            <Menu className="header-toolbar-pages-icon"/>
          </IconButton>}
          <div className="header-toolbar-page-name">{title ?? getCurrentPageWithDefault()?.name}</div>
          <div className="header-toolbar-spacer" />
          <BackgroundOperationsIndicator />
          {renderQuestionButton()}
        </Toolbar>
      </Container>
    </AppBar>
    <DPNavigationMenu ref={drawer} />
  </div>;

}

const Header = forwardRef<HeaderInterface, HeaderProps>(HeaderComponent);
export default Header;
